import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompleteAccountComponent } from './complete-account.component';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { SanitizeUrlPipe } from '../../pipes/safeUrl.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicLoaderComponent } from '../../dynamic-rendering/dynamic-loader.component';

@NgModule({
  declarations: [CompleteAccountComponent],
  imports: [
    CommonModule,
    MatListModule,
    MatProgressBarModule,
    RouterModule,
    MatRippleModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    MatButtonModule,
    MatStepperModule,
    SanitizeUrlPipe,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    DynamicLoaderComponent
  ],
  exports: [CompleteAccountComponent]
})
export class CompleteAccountModule {}
