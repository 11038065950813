import { Component, Input, OnChanges } from '@angular/core';
import { Select } from '@ngxs/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ComponentTemplate } from './dynamic-renderer/feature/render-template.types';
import { LanguageModel } from '../models/language.model';
import { AppHopState, FeaturesState } from '../state';
import { AppComponentsService } from '../services/app-components.service';
import { SubscriptionWebsocketResponse, WebsocketService } from '../services/websocket.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DynamicTemplatesModule } from './dynamic-renderer/feature/dynamic-templates.module';
import { FeaturesModel } from '../models';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [DynamicTemplatesModule],
  selector: 'hop-dynamic-loader',
  template: ` @if (components) {
    <app-render-template [components]="components" />
  }`
})
export class DynamicLoaderComponent implements OnChanges {
  @Input() slug: string;
  @Input() optional: boolean;
  @Select(AppHopState.selectLanguage) language$: Observable<LanguageModel>;
  @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  langCode: string;
  components: ComponentTemplate[];
  webSocketComponentUpdate$: BehaviorSubject<SubscriptionWebsocketResponse> = new BehaviorSubject<SubscriptionWebsocketResponse>(null);
  constructor(
    private appComponentsService: AppComponentsService,
    private websocketService: WebsocketService
  ) {
    combineLatest([this.language$, this.webSocketComponentUpdate$, this.features$]).subscribe(([language, update, features]) => {
      this.langCode = language.code;
      if (this.slug) {
        this.loadComponent(this.langCode, { isPreview: features.isPreview.value, isOptional: this.optional });
      }
    });
  }

  ngOnChanges(): void {
    if (this.slug) {
      this.webSocketComponentUpdate$.next(null);
    }
  }

  loadComponent(langCode: string, options: { isOptional?: boolean; isPreview?: boolean } = { isOptional: false, isPreview: false }): void {
    this.appComponentsService
      .getAppComponent(this.slug, langCode, this.webSocketComponentUpdate$.value, options.isPreview)
      .pipe(
        catchError((error) => {
          if (options.isOptional) {
            console.warn('Error loading optional component', error);
            return of(null);
          }
          throw error;
        })
      )
      .subscribe((component) => {
        this.websocketService.addUuidSubscription(component.uuid, this.webSocketComponentUpdate$, this);
        let components = [];
        if (Array.isArray(component.componentsJson)) {
          components = component.componentsJson;
        } else {
          components = [component.componentsJson];
        }
        this.components = components;
        console.log('loaded component', this.slug, this.langCode, component.uuid);
        /*setTimeout(() => {
          this.components = components;
        });*/
        //this.components = this.components.map((c) => this.processChildrenBySelector(c));
      });
  }
}
