import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map, catchError, throwError, combineLatest, switchMap, of, BehaviorSubject } from 'rxjs';
import { CachingService } from './caching.service';
import { ComponentTemplate } from '../dynamic-rendering/dynamic-renderer/feature/render-template.types';
import { AppHopState, FeaturesState } from '../state';
import { Select } from '@ngxs/store';
import { LanguageModel } from '../models/language.model';
import { FeaturesModel } from '../models';
import { SubscriptionWebsocket, SubscriptionWebsocketResponse, WebsocketService } from './websocket.service';

@Injectable({ providedIn: 'root' })
export class AppComponentsService {
  endpoint = `${this.environment.apiUrl}/components/app`;

  constructor(
    private http: HttpClient,
    @Inject('environment') private environment,
    private cachingService: CachingService,
    private websocketService: WebsocketService
  ) {}
  @Select(AppHopState.selectLanguage) language$: Observable<LanguageModel>;
  @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  getComponent(websocket$: BehaviorSubject<SubscriptionWebsocketResponse>, slug?: string): Observable<any> {
    return combineLatest([this.language$, websocket$, this.features$]).pipe(
      switchMap(([language, update, features]) => {
        slug = slug ?? 'page' + window.location.pathname;
        return this.getAppComponent(slug, language.code, update, features?.isPreview?.value)
          .pipe(
            catchError((error) => {
              return of(null);
            })
          )
          .pipe(
            map((component) => {
              if (!component) {
                return null;
              }
              this.websocketService.addUuidSubscription(component.uuid, websocket$, this);
              return component.componentsJson;
            })
          );
      })
    );
  }

  getAppComponent(slug, langCode, websocketValue, isPreview: boolean): Observable<AppComponent> {
    return this.cachingService.remember(
      slug + langCode + JSON.stringify(websocketValue) + isPreview,
      this.http.get<any>(this.endpoint + '/slug', { params: { slug, isPreview } }).pipe(
        map((data) => {
          return data;
        }),
        catchError((err) => {
          return throwError(err);
        })
      ),
      false,
      0.1
    );
  }

  getAppComponentSlug(slug): Observable<any> {
    return this.http.get<any>(this.endpoint + '/slug', { params: { slug } }).pipe(
      map((data) => {
        return data;
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
}

export interface AppComponent {
  title: string;
  subTitle: string;
  slug: string;
  uuid: string;
  dateTranslated: string | null;
  langCode: string;
  body: string;
  description: string | null;
  dataJson: any;
  variablesJson: any;
  type: string;
  componentsJson: ComponentTemplate[] | ComponentTemplate;
}
