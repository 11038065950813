import { Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, delay, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as cookie from 'js-cookie';

import { UserModel } from '../models';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { CachingService } from './caching.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  loginPopup$: Subject<string> = new Subject();

  constructor(
    private cacheService: CachingService,
    private router: Router,
    private http: HttpClient,
    @Inject('environment') private environment
  ) {
    this.processAuthCookie().then();
  }

  public async processAuthCookie() {
    const auth: AuthenticationPayload = JSON.parse(cookie.get('auth') || '{}');
    if (auth.user) {
      this.jwtToken$.next(auth.jwt.token);
      this.jwtRefreshToken$.next(auth.jwt.refreshToken);
      this.startRefreshTokenTimer();
      this.user$.next(auth.user);
      this.userS.set(auth.user);
      // console.log(auth);
    }
  }

  /*public async processAuthCookie() {
    setTimeout(() => {
      this.http.post(`${this.environment.apiUrl}/auth`, {}, { headers: { skipErrors: 'true' } }).subscribe((auth: any) => {
        if (auth.user) {
          this.jwtToken$.next(auth.jwt.token);
          this.jwtRefreshToken$.next(auth.jwt.refreshToken);
          this.startRefreshTokenTimer();
          this.user$.next(auth.user);
          // console.log(auth);
        }
      });
    }, 100);

    //const auth: AuthenticationPayload = JSON.parse(cookie.get('auth') || '{}');
  }*/

  public get user(): UserModel {
    return this.user$.value;
  }
  userS: WritableSignal<UserModel> = signal(null);
  user$: BehaviorSubject<UserModel> = new BehaviorSubject<UserModel>(null);
  jwtToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private jwtRefreshToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public get jwtToken(): string {
    return this.jwtToken$.value;
  }

  getUser() {
    return this.http.get<UserModel>(`${this.environment.apiUrl}/profile`);
  }
  // helper methods

  private refreshTokenTimeout;

  login(emailOrPhone: string, password: string, rememberMe = false): Observable<AuthenticationPayload> {
    const data: any = { username: emailOrPhone, password, rememberMe };
    //if is email
    if (!emailOrPhone.includes('@')) {
      data.isPhone = true;
    }
    return this.http
      .post<any>(
        `${this.environment.apiUrl}/login`,
        data // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          this.user$.next(data.user);
          this.userS.set(data.user);
          this.jwtToken$.next(data.jwt.token);
          this.jwtRefreshToken$.next(data.jwt.refreshToken);
          this.setAuthCookie(data);
          this.startRefreshTokenTimer();
          return data;
        })
      );
  }

  logout(fromUser = false) {
    /*cookie.remove('lang');
    cookie.remove('auth');
    const domain = environment.mainUrl.replace(/https?:\/\//, '');
    cookie.remove('auth', {
      secure: true,
      sameSite: 'lax',
      domain
    });*/
    localStorage.clear();
    if (!this.jwtRefreshToken$.value && !this.jwtToken$.value) {
      return of(true);
    }
    this.http
      .post<any>(
        `${this.environment.apiUrl}/logout`,
        { refreshToken: this.jwtRefreshToken$.value }
        // { withCredentials: true }
      )
      .pipe(
        tap(() => {
          if (fromUser) {
            //this.router.navigate(['/']);
          }
          this.clearStorageAndRefresh();
        }),
        catchError((err) => {
          if (fromUser) {
            this.router.navigate(['/']);
          }
          this.clearStorageAndRefresh();
          throw new Error(JSON.stringify(err));
        })
      )
      .subscribe();
    // @ts-ignore
    if (window.$chatwoot) {
      try {
        // @ts-ignore
        window.$chatwoot.reset();
      } catch (e) {
        console.warn("can't reset chatwoot");
      }
    }
    return of(true);
  }

  clearStorageAndRefresh() {
    this.stopRefreshTokenTimer();
    this.user$.next(null);
    this.userS.set(null);
    this.jwtToken$.next(null);
    this.jwtRefreshToken$.next(null);
    cookie.remove('lang');
    const domain = environment.mainUrl.replace(/https?:\/\//, '');
    cookie.remove('auth');
    cookie.remove('auth', {
      secure: true,
      sameSite: 'lax',
      domain
    });
    localStorage.clear();
    setTimeout(() => {
      location.reload();
    }, 500);
  }

  refreshToken(): Observable<AuthenticationPayload> {
    if (!this.jwtRefreshToken$.value || this.jwtToken$.value) {
      return of(null);
    }
    return this.http
      .post<any>(
        `${this.environment.apiUrl}/refresh-token`,
        { refreshToken: this.jwtRefreshToken$.value } // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          this.user$.next(data.user);
          this.userS.set(data.user);
          this.jwtToken$.next(data.jwt.token);
          data.jwt.refreshToken = this.jwtRefreshToken$.value;
          this.setAuthCookie(data);
          this.startRefreshTokenTimer();
          return data;
        })
      );
  }

  registerEmail(email: string, emailCode: string, options: UserRegisterOptions): Observable<AuthenticationPayload> {
    const data: any = { email, emailCode, ...options };
    return this._register(data);
  }

  registerPhone(phone: string, phoneCode: string, options: UserRegisterOptions): Observable<AuthenticationPayload> {
    const data: any = { phone, phoneCode, ...options };
    return this._register(data);
  }

  _register(data: {
    firstName: string;
    lastName: string;
    email?: string;
    emailCode?: string;
    phone?: string;
    phoneCode?: string;
    isProfessional: boolean;
    termsAccepted: boolean;
    username?: string;
    password?: string;
  }): Observable<AuthenticationPayload> {
    return this.http.post<any>(`${this.environment.apiUrl}/register`, data).pipe(
      map((data) => {
        this.user$.next(data.user);
        this.userS.set(data.user);
        this.jwtToken$.next(data.jwt.token);
        this.jwtRefreshToken$.next(data.jwt.refreshToken);
        this.setAuthCookie(data);
        this.startRefreshTokenTimer();
        return data;
      })
    );
  }

  verifyEmail(code): Observable<any> {
    // return of({ success: true, message: 'hey' });
    return this.http.post<any>(
      `${this.environment.apiUrl}/confirm-email`,
      { code } // { withCredentials: true }
    );
  }

  resendEmailCode(email): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/confirm-email-resend`,
      { email } // { withCredentials: true }
    );
  }

  recoverPassword(email): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/recover-password`,
      { email } // { withCredentials: true }
    );
  }

  resetPasswordEmail(email, emailCode, password): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/change-password-email`,
      { email, emailCode, password } // { withCredentials: true }
    );
  }
  resetPasswordPhone(phone, phoneCode, password): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/change-password-phone`,
      { phone, phoneCode, password } // { withCredentials: true }
    );
  }

  changePasswordUserEmail(emailCode, newPassword): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/change-password-user-email`,
      { emailCode, newPassword } // { withCredentials: true }
    );
  }

  changePasswordUserPhone(phoneCode, newPassword): Observable<any> {
    return this.http.post<any>(
      `${this.environment.apiUrl}/change-password-user-phone`,
      { phoneCode, newPassword } // { withCredentials: true }
    );
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.jwtToken$.value.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private setAuthCookie(data) {
    data = JSON.parse(JSON.stringify(data));
    const isLocalhost = /localhost|127|100|192/.test(window.location.hostname);
    if (isLocalhost) {
      data.user = {
        uuid: data.user.uuid,
        lastName: data.user.lastName,
        email: data.user.email,
        username: data.user.username,
        firstName: data.user.firstName,
        isEmailVerified: data.user.isEmailVerified,
        isProfessional: data.user.isProfessional
      };
      //console.log('isLocalhost', isLocalhost);
      cookie.set('auth', data, { expires: 183000, secure: true, sameSite: 'lax', domain: '' });
    }
  }
  public emailStatus(email): Observable<UserStatus> {
    return this.http.post<any>(`${this.environment.apiUrl}/authorization/user-status`, { email });
  }

  public phoneStatus(phone): Observable<UserStatus> {
    return this.http.post<any>(`${this.environment.apiUrl}/authorization/user-status`, { phone });
  }

  sendEmailCodeNewClient(email, checkIfEmailExists) {
    return this.http
      .post<any>(
        `${this.environment.apiUrl}/send-email-code`,
        { email, checkIfEmailExists } // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  sendPhoneCodeNewClient(phone, checkIfPhoneExists) {
    return this.cacheService.remember(
      'send-phone-code-' + phone,
      this.http
        .post<any>(
          `${this.environment.apiUrl}/send-phone-code`,
          { phone, checkIfPhoneExists } // { withCredentials: true }
        )
        .pipe(
          map((data) => {
            return data;
          })
        ),
      false,
      0.5
    );
  }

  loginWithEmailCode(email: string, emailCode: string): Observable<AuthenticationPayload> {
    return this.http
      .post<any>(
        `${this.environment.apiUrl}/login-email-code`,
        { email, emailCode } // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          this.user$.next(data.user);
          this.userS.set(data.user);
          this.jwtToken$.next(data.jwt.token);
          this.jwtRefreshToken$.next(data.jwt.refreshToken);
          this.setAuthCookie(data);
          this.startRefreshTokenTimer();
          return data;
        })
      );
  }

  loginWithPhoneCode(phone: string, phoneCode: string): Observable<AuthenticationPayload> {
    return this.http
      .post<any>(
        `${this.environment.apiUrl}/login-phone-code`,
        { phone, phoneCode } // { withCredentials: true }
      )
      .pipe(
        map((data) => {
          this.user$.next(data.user);
          this.userS.set(data.user);
          this.jwtToken$.next(data.jwt.token);
          this.jwtRefreshToken$.next(data.jwt.refreshToken);
          this.setAuthCookie(data);
          this.startRefreshTokenTimer();
          return data;
        })
      );
  }
  needsPassword(newPassword) {
    return this.http.post<any>(`${this.environment.apiUrl}/change-password-user-needs-password`, { newPassword });
  }

  goToLastUrl() {
    const lastUrl = cookie.get('lastUrl');
    this.router.navigate([lastUrl ?? '/']).then();
  }

  switchUser(isProfessional: boolean) {
    return this.http.post<any>(`${this.environment.apiUrl}/switch-user`, { isProfessional }).pipe(delay(3000));
  }
}

export interface AuthenticationPayload {
  user: UserModel;
  isAdmin?: boolean;
  jwt: {
    token: string;
    refreshToken?: string;
  };
}

export interface UserStatus {
  success: boolean;
  status: 'available' | 'unavailable' | 'exists' | 'exists_';
}

export interface UserRegisterOptions {
  firstName: string;
  lastName: string;
  isProfessional: boolean;
  termsAccepted: boolean;
  username?: string;
  password?: string;
}
