import { DynamicItemConstructor } from '../feature/render-template.types';

type ComponentMap = {
  [name: string]: {
    loadComponent: () => Promise<DynamicItemConstructor>;
  };
};

const _dynamicComponentMap: ComponentMap = {
  'hop-create-profile': {
    loadComponent: () => import('../../dynamic-components/components').then((m) => m.CreateProfileComponent)
  },
  'hop-four-images': {
    loadComponent: () => import('../../dynamic-components/components').then((m) => m.FourImagesComponent)
  },
  'hop-book-to-join': {
    loadComponent: () => import('../../dynamic-components/components').then((m) => m.BookToJoinComponent)
  },
  'hop-layout-4-6': {
    loadComponent: () => import('../../dynamic-components/layouts').then((m) => m.Layout46Component)
  },
  'hop-layout-3-columns': {
    loadComponent: () => import('../../dynamic-components/layouts').then((m) => m.Layout3ColumnsComponent)
  },
  'hop-layout-page': {
    loadComponent: () => import('../../dynamic-components/layouts').then((m) => m.LayoutPageComponent)
  },
  'hop-pricing': {
    loadComponent: () => import('../../dynamic-components/components/pricing/pricing.component').then((m) => m.PricingComponent)
  },
  'hop-price-item': {
    loadComponent: () => import('../../dynamic-components/components/pricing/price-item.component').then((m) => m.PriceItemComponent)
  },
  'hop-body-md': {
    loadComponent: () => import('../../dynamic-components/components/body-md.component').then((m) => m.BodyMdComponent)
  },
  'hop-body-html': {
    loadComponent: () => import('../../dynamic-components/components/body-html.component').then((m) => m.BodyHtmlComponent)
  },
  'hop-wizard': {
    loadComponent: () => import('../../dynamic-components/components/wizard-complete-account/wizard.component').then((m) => m.WizardComponent)
  }
};

export const dynamicComponentMap = new Map(Object.entries(_dynamicComponentMap));
