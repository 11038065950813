import { computed, effect, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfigModel, AppUserConfigModel } from '../models/app-config.model';
import * as cookie from 'js-cookie';
import { SubscriptionWebsocketResponse, WebsocketService } from './websocket.service';
import { UserState } from '../state';
import { Select } from '@ngxs/store';
import { UserModel } from '../models';
import { AuthenticationService } from './authentication.service';
import { LayoutService } from './layout.service';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  endpoint = `${this.environment.apiUrl}/config`;
  fromWebsocketConfig$: BehaviorSubject<SubscriptionWebsocketResponse> = new BehaviorSubject<SubscriptionWebsocketResponse>(null);
  config: WritableSignal<AppUserConfigModel[]> = signal(null);
  isEnabledWizard: Signal<boolean> = computed(() => !this.config()?.find((c) => c.name === 'isDisabledWizard')?.value?.value);

  constructor(
    private http: HttpClient,
    @Inject('environment') public environment,
    private webSocketService: WebsocketService,
    private authService: AuthenticationService,
    private layoutService: LayoutService
  ) {
    combineLatest([this.fromWebsocketConfig$, this.authService.user$]).subscribe(([websocket, user]) => {
      this.getUserConfig().subscribe((config) => {
        this.config.set(config);
        this.webSocketService.addObjectsSubscription('config', user.uuid, this.fromWebsocketConfig$, this);
      });
    });
    effect(() => {
      if (this.isEnabledWizard() && this.authService.userS()?.isProfessional) {
        this.verifyWizardCookie();
      }
    });
  }

  getConfig(): Observable<AppConfigModel> {
    return this.http.get<any>(this.endpoint + '/').pipe(
      map((data) => {
        return data;
      })
    );
  }

  setConfig(): Observable<any> {
    return this.getConfig().pipe(
      tap((config) => {
        /*if (config.ipLangCode && !cookie.get('lang')) {
          cookie.set('lang', config.ipLangCode);
        }*/
        this.environment.appConfig = { ...config };
      })
    );
  }

  setUserConfig(config: AppUserConfigModel): Observable<any> {
    return this.http.post<any>(this.endpoint + '/my', config).pipe(
      map((data) => {
        return data;
      })
    );
  }

  getUserConfig(): Observable<AppUserConfigModel[]> {
    return this.http.get<any>(this.endpoint + '/my').pipe(
      map((config: AppUserConfigModel[]) => {
        return config;
      })
    );
  }

  configWizard(statusObject: { selectorUuid: string; statusType: string }): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/my/wizard`, statusObject);
  }

  private verifyWizardCookie() {
    if (cookie.get('doNotOpenWizard')) {
      return;
    } else {
      this.layoutService.openCompleteAccount();
      cookie.set('doNotOpenWizard', 'true', { expires: 7 });
    }
  }
}
