import { Component, computed, effect, OnInit, Signal, WritableSignal } from '@angular/core';
import { LayoutService } from 'src/@hop/services/layout.service';
import { FeatureEnum, FeaturesModel, UserModel } from '../../models';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { IfFeatureFlagDirective } from '../is-feature/is-feature.directive';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FeaturesState, UserState } from 'src/@hop/state';
import { AppConfigService } from 'src/@hop/services/app-config.service';
import { AppConfigModel, AppUserConfigModel } from 'src/@hop/models/app-config.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-hop-complete-account-button',
  templateUrl: './hop-complete-account.component.html',
  styleUrls: ['./hop-complete-account.component.scss'],
  standalone: true,
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, MatCheckboxModule, IfFeatureFlagDirective]
})
export class AppCompleteAccountButtonComponent implements OnInit {
  activate: boolean = false;
  @Select(UserState.selectUser)
  user$: Observable<UserModel>;
  // @Select(FeaturesState.selectFeatures) features$: Observable<FeaturesModel>;
  constructor(
    private layoutService: LayoutService,
    public appConfigService: AppConfigService
  ) {}

  ngOnInit(): void {}

  // setFeatureValue(name, value) {
  //   this.store.dispatch(new FeatureSet(name, value));
  // }

  openCompleteAccount(): void {
    if (!this.layoutService.isCompleteAccountOpen) {
      this.layoutService.openCompleteAccount();
    } else {
      this.layoutService.closeCompleteAccount();
    }
  }

  protected readonly FeatureEnum = FeatureEnum;
}
